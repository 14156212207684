import React, { useContext } from 'react';
import Layout from '@/components/Layout';
import { gql, useMutation } from '@apollo/client';
import UserContext from '@/hooks/UserContext';
import { useRouter } from 'next/router';
import jwt from 'jsonwebtoken';
import { LOGIN } from './api/Auth';
import styles from '@/styles/Login.module.scss';
import Link from 'next/link';

export default function Login() { 
  const router = useRouter();
  const [authUser, { error, loading }] = useMutation(LOGIN); 
  const { setUser } = useContext(UserContext);

  const loginUser = async (e) => {
    e.preventDefault();
    const form = e.target;
    const input = {
      email: form.email.value,
      password: form.password.value,
    };
    try {
      const { data } = await authUser({
        variables: {
          input,
        },
      });
      localStorage.setItem('token', data.authUser.token);
      const decodedUser = jwt.decode(data.authUser.token);
      setUser(decodedUser);
      router.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
      <div className={styles.login}>
        <div className={styles.container_image}>
            <Link href="/">
              Home
            </Link>
        </div>
        <div className={styles.container}>
            <div className={styles.login_header}>
              <h1>
                Tokkiro
              </h1>
            </div>
          <form onSubmit={loginUser}>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" />
            <label htmlFor="password">Contraseña</label>
            <input type="password" name="password" id="password" />
            <button type="submit">Iniciar Sesión</button>
          </form>
        </div>
      </div>
  );
}
